exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gde-kupit-tsx": () => import("./../../../src/pages/gde-kupit.tsx" /* webpackChunkName: "component---src-pages-gde-kupit-tsx" */),
  "component---src-pages-in-develop-tsx": () => import("./../../../src/pages/in-develop.tsx" /* webpackChunkName: "component---src-pages-in-develop-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kalkulyator-alkogolya-v-krovi-tsx": () => import("./../../../src/pages/kalkulyator-alkogolya-v-krovi.tsx" /* webpackChunkName: "component---src-pages-kalkulyator-alkogolya-v-krovi-tsx" */),
  "component---src-pages-o-kompanii-tsx": () => import("./../../../src/pages/o-kompanii.tsx" /* webpackChunkName: "component---src-pages-o-kompanii-tsx" */),
  "component---src-pages-vaulin-tsx": () => import("./../../../src/pages/vaulin.tsx" /* webpackChunkName: "component---src-pages-vaulin-tsx" */),
  "component---src-templates-posts-archive-archive-tsx": () => import("./../../../src/templates/posts/Archive/Archive.tsx" /* webpackChunkName: "component---src-templates-posts-archive-archive-tsx" */),
  "component---src-templates-posts-instruction-instruction-tsx": () => import("./../../../src/templates/posts/Instruction/Instruction.tsx" /* webpackChunkName: "component---src-templates-posts-instruction-instruction-tsx" */),
  "component---src-templates-posts-legal-legal-tsx": () => import("./../../../src/templates/posts/Legal/Legal.tsx" /* webpackChunkName: "component---src-templates-posts-legal-legal-tsx" */),
  "component---src-templates-posts-post-post-tsx": () => import("./../../../src/templates/posts/Post/Post.tsx" /* webpackChunkName: "component---src-templates-posts-post-post-tsx" */),
  "component---src-templates-sitemap-sitemap-tsx": () => import("./../../../src/templates/sitemap/Sitemap.tsx" /* webpackChunkName: "component---src-templates-sitemap-sitemap-tsx" */)
}

